import React from 'react';

const DigitalBottom = () => {
  const services = [
    {
      title: "Increased Online Visibility",
      description: "Our services elevate your brand's online presence, ensuring you're easily discoverable by your target audience.",
      icon: './assets/img/client/service1.png', // Use appropriate icon here
    },
    {
      title: "Enhanced Customer Engagement",
      description: "We use strategic digital marketing to create meaningful interactions with your audience that build enduring relationships and brand loyalty.",
      icon: './assets/img/client/service2.png',// Use appropriate icon here
    },
    {
      title: "Customized Strategies",
      description: "Our services offer individualized digital marketing strategies for the best outcomes, according to your company's demands.",
      icon: './assets/img/client/service3.png',// Use appropriate icon here
    },
    {
      title: "Adaptability and Growth",
      description: "With our services, your brand stays ahead of the digital curve, adjusts to market developments, and ensures the steady growth of your company.",
      icon: './assets/img/client/service4.png', // Use appropriate icon here
    },
    {
      title: "Comprehensive Analytics",
      description: "With comprehensive analytics, we improve your digital presence with proper strategies by learning about campaign results and making well-informed decisions.",
      icon: './assets/img/client/service5.png', // Use appropriate icon here
    },
    {
      title: "Strong Team Support",
      description: "With the knowledge, innovation, and strong commitment to your company's success, our dedicated staff ensures that your digital marketing initiatives are supported and delivered on time.",
      icon: './assets/img/client/service6.png', // Use appropriate icon here
    }
  ];

  return (
    <div className="digital-section">
      <h2>Value You Get From Our Services!</h2>
      <div className="services-container">
        {services.map((service, index) => (
          <div className="service-card" key={index}>
            <img src={service.icon} alt={service.title} className="icon" />
            <h3>{service.title}</h3>
            <p>{service.description}</p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default DigitalBottom;
