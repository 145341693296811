import React from "react";
import AboutAreaOne from "../components/About/AboutAreaOne";
import Layout from "../components/Common/Layout";
import BannerOne from "../components/BannerOne";
import CounterAreaOne from "../components/Counters/CounterAreaOne";
import ServiceAreaFour from "../components/Services/ServiceAreaFour";
import WorkProcessEight from "../components/Work/WorkProcessEight";
import ContactAreaFour from "../components/Contacts/ContactAreaFour";
import TestimonialThree from "../components/TestimonialThree";
import TechSection from "../components/TechSection";
import CounterAreaFour from "../components/Counters/CounterAreaFour";
import ProjectAreaOne from "../components/ProjectAreaOne";


const HomeOne = () => {
  return (
    <Layout breadcrumbTitle="Home" showBreadcrumb={false}>
      {/* Banner One */}
      <BannerOne />

      {/* About Area One */}
      <AboutAreaOne />

      {/* Service Area One */}
      <ServiceAreaFour />

      {/* Work Process One */}
      <WorkProcessEight />

      {/* Counter Area One */}
      <CounterAreaOne />

      {/* Project Area One */}
      <ProjectAreaOne />

      {/* Tech Section */}
      <TechSection />

      {/* Testimonial Three */}
      <TestimonialThree />

      {/* Counter Area Four */}
      <CounterAreaFour />

      {/* Contact Area Four */}
      <ContactAreaFour />
    </Layout>
  );
};

export default HomeOne;
