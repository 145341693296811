import React from 'react';
import {
  FaChevronRight,
  FaEnvelope,
  // FaFacebookF,
  FaInstagram,
  // FaMapMarkerAlt,
  FaPhoneAlt,
  FaWhatsapp,
  // FaTwitter,
} from 'react-icons/fa';

import { Link } from 'react-router-dom';

const FooterNine = () => {

  return (
    <>
      {/* footer area start */}
      <footer className="footer-area  footer-area_4 bg-cover mt-0 pd-top-120"
        style={{ backgroundImage: 'url("assets/img/bg/14.png")' }}
      >
        <div className="container">
          <div className="row">
            <div className="col-lg-3 col-md-6">
              <div className="widget widget_about">
                <div className="thumb">
                  <img src="assets/img/Raxos-logo1.png" alt="img" />
                </div>
                <div className="details">
                  <p>
                    Honing unique processes that delivers exceptional results with breath-taking efficiency to meet revenue goals.
                  </p>
                  {/* <div className="subscribe mt-4">
                    <input type="text" placeholder="E-mail" />
                    <button>
                      <FaChevronRight className="mb-1" />
                    </button>
                  </div> */}
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-md-6 ps-xl-5">
              <div className="widget widget_nav_menu">
                <h4 className="widget-title">Our Service</h4>
                <ul>
                  <li>
                    <Link to="/E-Commerce">
                      <FaChevronRight className="mb-1" /> E-commerce
                    </Link>
                  </li>
                  <li>
                    <Link to="/WebDevelopment">
                      <FaChevronRight className="mb-1" /> Web Design / Development
                    </Link>
                  </li>
                  <li>
                    <Link to="/DigitalMarketing">
                      <FaChevronRight className="mb-1" /> Digital marketing{' '}
                    </Link>
                  </li>
                  <li>
                    <Link to="/MobileDevelopment">
                      <FaChevronRight className="mb-1" /> Mobile Design / Development
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-lg-3 col-md-6 ps-xl-5">
              <div className="widget widget_nav_menu">
                <h4 className="widget-title">Links</h4>
                <ul>
                  <li>
                    <Link to="/about">
                      <FaChevronRight className="mb-1" /> About us
                    </Link>
                  </li>
                  {/* <li>
                    <Link to="/portfolio">
                      <FaChevronRight className="mb-1" /> Portfolio
                    </Link>
                  </li> */}
                  <li>
                    <Link to="/contact">
                      <FaChevronRight className="mb-1" /> Contact Us
                    </Link>
                  </li>
                  <li>
                    <Link to="/Terms&Conditions">
                      <FaChevronRight className="mb-1" /> Terms & Conditions
                    </Link>
                  </li>
                  <li>
                    <Link to="/PrivacyPolicy">
                      <FaChevronRight className="mb-1" /> Privacy Policy
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-lg-3 col-md-6">
              <div className="widget widget-recent-post">
                <h4 className="widget-title">Contact us</h4>
                <div className="widget widget_contact">
                  <ul className="details">
                    {/* <li>
                      <FaMapMarkerAlt />
                      4517 Washington Ave. Manchester, Kentucky 39495
                    </li> */}
                    <li className="mt-3">
                      <FaPhoneAlt className="mb-1" />
                      <a href={`tel:${process.env.REACT_APP_OFFICIAL_PHONE}`}>
                        {process.env.REACT_APP_OFFICIAL_PHONE}
                      </a>
                    </li>
                    <li className="mt-2">
                      <FaEnvelope className="mb-1" />
                      <a href={`mailto:${process.env.REACT_APP_OFFICIAL_EMAIL}`}>
                        {process.env.REACT_APP_OFFICIAL_EMAIL}
                      </a>
                    </li>
                  </ul>
                  <ul className="social-media mt-4">
                    <li>
                      <Link to={process.env.REACT_APP_LINKS_WHATSAPP} target="_blank" rel="noopener noreferrer">
                        <FaWhatsapp />
                      </Link>
                    </li>
                    <li>
                      <a href={process.env.REACT_APP_LINKS_INSTAGRAM} target="_blank" rel="noopener noreferrer">
                        <FaInstagram />
                      </a>
                    </li>
                    {/* <li>
                      <Link to={process.env.REACT_APP_LINKS_FACEBOOK}>
                        <FaFacebookF />
                      </Link>
                    </li>
                    <li>
                      <Link to={process.env.REACT_APP_LINKS_X}>
                        <FaTwitter />
                      </Link>
                    </li> */}
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="footer-bottom">
          <div className="container">
            <div className="row">
              <div className="col-md-12 text-center">
                <p>Made with ❤️ by us | © Raxos 2024 | All Rights Reserved</p>
              </div>
              {/* <div className="col-md-6 text-lg-end">
                <Link to="/Terms&Conditions">Terms &amp; Condition</Link>
                <Link to="/PrivacyPolicy">Privacy Policy</Link>
                <Link to="/contact">Contact Us</Link>
              </div> */}
            </div>
          </div>
        </div>
      </footer>
      {/* footer area end */}
    </>
  );
};

export default FooterNine;
