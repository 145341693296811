import React from "react";

const MobileCard = () => {
  const cards = [
    {
      title: "iOS App Development",
      description: "Our aim is to bring you closer to realizing your dream. We build scalable, secure, and high-performance iOS apps. User satisfaction and product management are our top priorities.",
      services: ["iPhone App Development", "iPad App Development", "Swift App Development"],
      icon: "./assets/img/client/iso-logo.svg" // Placeholder for icon
    },
    {
      title: "Android App Development",
      description: "Let us help you create and design Android applications for a variety of platforms. We help fix bugs in existing and new applications. What we ensure is a seamless user experience.",
      services: ["Kotlin App Development"],
      icon: "./assets/img/client/android-logo.svg" // Placeholder for icon
    },
    {
      title: "Cross-platform App Development",
      description: "We provide cross-platform app development services that allow reusability of code and fast compilation at a reasonable cost with service support.",
      services: ["React Native App Development", "Flutter App Development", "Ionic App Development"],
      icon: "./assets/img/client/cross-logo.svg" // Placeholder for icon
    },
    {
      title: "Hybrid app development",
      description: "Let your business stand out with smart hybrid mobile app development. We design and build user-centric hybrid apps that work smoothly across different platforms using HTML5, CSS3, JavaScript, and others.",
      services: ["React Native App Development", "Flutter App Development", "Ionic App Development"],
      icon: "./assets/img/client/hybrid-logo.svg" // Placeholder for icon
    },
    {
      title: "PWA development",
      description: "A progressive web app combines the advantages of two worlds and delivers a smooth user experience and reaches a greater number of customers. ",
      services: ["React Native App Development", "Flutter App Development", "Ionic App Development"],
      icon: "./assets/img/client/pwa-logo.svg" // Placeholder for icon
    },
    {
      title: "End-to-end development ",
      description: "We develop iOS and Android apps from scratch. With our skills, we can transform your idea into a mobile app that combines Cloud services, APIs, payment gateways, and third-party libraries.",
      services: [""],
      icon: "./assets/img/client/end-end-logo.svg" // Placeholder for icon
    }
  ];
  return (
    <>
      <div className="card-head">
        <div>
          <div className="mobile-head">
            <h3>Mobile App Development Services We Provide</h3>
            <h4 className="mobile-sub">
              We focus on building user-friendly, easy to navigate, and perceptive Android and iOS mobile apps
              {/* Latest <span>Project</span> Are Here */}
            </h4>
          </div>
        </div>
      </div>
      <div className="card-section">
        {cards.map((card, index) => (
          <div key={index} className="card">
            <div>
              <img className="card-icon" src={card.icon} />
            </div>
            <h3>{card.title}</h3>
            <p>{card.description}</p>
            {index < 3 && ( // Only show services for the first 3 cards
              <ul>
                {card.services.map((service, i) => (
                  <li key={i}>{service}</li>
                ))}
              </ul>
            )}
          </div>
        ))}
      </div>
    </>

  );
}
export default MobileCard;