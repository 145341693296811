import React from "react";
import Layout from "../components/Common/Layout";
import PrivacyPolicy from "../components/Common/PrivacyPolicy";

const Contact = () => {
    return (
        <Layout breadcrumbTitle="Privacy Policy">
            {/* Privacy Policy */}
            <PrivacyPolicy />

        </Layout>
    );
};

export default Contact;
