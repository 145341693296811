import { SendMailClient } from 'zeptomail';

const sendEmail = async ({ fromEmail, toEmail, subject, message }) => {
    const apiUrl = process.env.REACT_APP_API_URL || 'https://api.zeptomail.in/';
    const token = process.env.REACT_APP_API_KEY; // Store the token in environment variables

    if (!token) {
        throw new Error('API key is not defined');
    }

    const client = new SendMailClient({
        url: apiUrl,
        token: token,
    });

    const emailPayload = {
        from: {
            address: fromEmail,
            name: "Client", // Name to display in email
        },
        to: [
            {
                email_address: {
                    address: toEmail,
                    name: "Raxos", // Add recipient name if needed
                },
            },
        ],
        subject: subject,
        htmlbody: message, // HTML email body
        bounce_address: 'official@raxos.in', // Should be an existing email address
    };

    try {
        // Send email
        const response = await client.sendMail(emailPayload);

        // Log the complete response
        console.log('Response:', response);

        // Check for success
        if (response && response.success) {
            console.log('Email sent successfully:', response);
            return response; // Return success response
        } else {
            throw new Error(`Failed to send email: ${response.message || 'Unknown error'}`);
        }
    } catch (error) {
        console.error('Error sending email:', error);
        throw error; // Re-throw error to handle it in the calling function
    }
}

export { sendEmail };
