import { BrowserRouter, Routes, Route } from 'react-router-dom';
import About from './pages/About';
import Contact from './pages/Contact';
import HomeOne from './pages/HomeOne';
import 'bootstrap/dist/css/bootstrap.min.css';
import AOS from 'aos';
import 'aos/dist/aos.css';
import { useEffect } from 'react';
import ScrollToTop from 'react-scroll-to-top';
import DigitalMarketing from './pages/DigitalMarketing';
import Portfolio from './pages/Portfolio';
import WebDevelopment from './pages/WebDevelopement';
import MobileDevelopment from './pages/MobileDevelopement';
import ECommerce from './pages/E-Commerce';
import TermsConditions from './pages/TermsConditions';
import PrivacyPolicy from './pages/PrivacyPolicy';


function App() {
  useEffect(() => {
    AOS.init({
      offset: 0,
      easing: 'ease',
      once: true,
    });
    AOS.refresh();
  }, []);
  return (

    <BrowserRouter>
      <Routes>
        <Route exact path="/" element={<HomeOne />} />
        <Route exact path="/portfolio" element={<Portfolio />} />
        <Route exact path="/contact" element={<Contact />} />
        <Route exact path="/about" element={<About />} />
        <Route exact path="/Terms&Conditions" element={<TermsConditions />} />
        <Route exact path="/PrivacyPolicy" element={<PrivacyPolicy />} />
        <Route exact path="/WebDevelopment" element={<WebDevelopment />} />
        <Route exact path="/E-Commerce" element={<ECommerce />} />
        <Route exact path="/MobileDevelopment" element={<MobileDevelopment />} />
        <Route exact path="/DigitalMarketing" element={<DigitalMarketing />} />
      </Routes>
      <ScrollToTop smooth color="#246BFD" className='mb-5 me-0' />
    </BrowserRouter>
  );
}

export default App;
