import React from 'react';
import Layout from "../components/Common/Layout";
import KeyFactors from '../components/KeyFactors';
import ServiceAreaGroupTwo from '../components/Services/ServiceAreaGroupTwo';
import DigitalBanner from '../components/Digital/DigitalBanner';
import DigitalBottom from '../components/Digital/DigitalBottom';
import ContactAreaFour from '../components/Contacts/ContactAreaFour';

const ServiceTwo = () => {
  return (
    <Layout breadcrumbTitle="Digital Marketing">
      {/* Main Banner */}
      <DigitalBanner />

      {/* Service Area */}
      <ServiceAreaGroupTwo />

      {/* KeyFactors */}
      <KeyFactors />

      <DigitalBottom />

      {/* Contact */}
      <ContactAreaFour />
    </Layout>
  );
};

export default ServiceTwo;
