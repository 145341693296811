import React from 'react';

const MobileBottom = () => {
  return (
    <div className="mobile-app-section">
      {/* Left Side - Image and Card */}
      <div className="left-side">
        <div className="mobile-image">
          {/* First Mobile Image */}
          <img src="./assets/img/client/mobile-app-phone.png" alt="Mobile" />
          {/* Floating Card Image */}
          <div className="card-image">
            <img src="./assets/img/client/mobile-app-map.png" alt="Card" />
          </div>
        </div>
      </div>

      {/* Right Side - Flex-based Services */}
      <div className="right-side">
        <h2>End-to-End Mobile App Development Solutions</h2>
        <p>
          From the requirements analysis phase to post-development support, we offer
          end-to-end mobile development solutions that will help your business succeed.
        </p>
        <div className="services">
          <div className="service-card">
            <img src="./assets/img/client/Consulting.svg" alt="Consulting" />
            <div className="service-info">
              <h3>Consulting</h3>
              <p>Analyze existing app, challenges, and market research data to develop a strategy that aligns with your business goals.</p>
            </div>
          </div>
          <div className="service-card">
            <img src="./assets/img/client/ux-ui-design.svg" alt="UI/UX Design" />
            <div className="service-info">
              <h3>UI/UX Design</h3>
              <p>Design positive and rich user experiences across devices with a focus on efficiency and discoverability.</p>
            </div>
          </div>
          <div className="service-card">
            <img src="./assets/img/client/custom-app-development.svg" alt="Custom App Development" />
            <div className="service-info">
              <h3>Custom App Development</h3>
              <p>Develop customized solutions using iOS, Android, and Cross-platform development</p>
            </div>
          </div>
          <div className="service-card">
            <img src="./assets/img/client/qaandtesting.svg" alt="QA and Testing" />
            <div className="service-info">
              <h3>QA and Testing</h3>
              <p>Provide an optimal user experience by ensuring proper functionality, usability, and accessibility.</p>
            </div>
          </div>
          <div className="service-card">
            <img src="./assets/img/client/maintananceandsupport.svg" alt="Maintenance and Support" />
            <div className="service-info">
              <h3>Maintenance and Support</h3>
              <p>Monitor, refine, and update elements to accommodate evolving business needs.</p>
            </div>
          </div>
          <div className="service-card">
            <img src="./assets/img/client/deticated-team.svg" alt="Dedicated Team" />
            <div className="service-info">
              <h3>Dedicated Team</h3>
              <p>Collaborate with an outsourced team of developers using agile processes and continuous attention to technical excellence.</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MobileBottom;
