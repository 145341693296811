import React from 'react';
import Layout from '../components/Common/Layout';
import ServiceAreaGroup from '../components/Services/ServiceAreaGroup';
import DigitalBottom from '../components/Digital/DigitalBottom';
import WorkArea from '../components/Work/WorkArea';
import ContactAreaFour from "../components/Contacts/ContactAreaFour";
import FaqAreaOne from '../components/Faqs/FaqAreaOne';

const WebDevelopment = () => {
  return (
    <Layout breadcrumbTitle={'Web Developement'}>
      {/* Service Area One */}
      <ServiceAreaGroup />

      {/* Digital Bottom Content */}
      {<DigitalBottom />}

      {/* Work Area */}
      <WorkArea />

      {/* Faq Area */}
      <FaqAreaOne />

      {/* Contact Area */}
      <ContactAreaFour />
    </Layout>
  );
};

export default WebDevelopment;