import React, { useState } from 'react';
import { FaPlus } from 'react-icons/fa';
import { Link } from 'react-router-dom';

// Constant for the message
const NO_PROJECT_MESSAGE = "You are the first client!";

// Data arrays for tabs and projects
const tabs = ['App Development', 'Web Development', 'Digital Marketing'];
const projects = [
  {
    title: 'MPVCity',
    type: 'Web Development', // Updated to match the tab name
    imgFolder: 'assets/img/client/mpvcity/',
    imgName: '1.png',
    link: '/WebDevelopment',
  },
  {
    title: "Rathi's Naturals",
    type: 'Web Development',
    imgFolder: 'assets/img/client/rathis/',
    imgName: '1.png',
    link: '/WebDevelopment',
  },
];

const ProjectAreaOne = () => {
  const [activeTab, setActiveTab] = useState('View All'); // Manage active tab state

  // Function to filter projects based on type
  const getFilteredProjects = (tab) => {
    if (tab === 'View All') {
      return projects; // Show all projects for "View All"
    }
    return projects.filter((project) => project.type === tab);
  };

  const renderTabs = () =>
    tabs.map((tab, index) => (
      <button
        key={index}
        className={`nav-link ${activeTab === tab ? 'active' : ''}`}
        id={`nav-tabs${index + 1}-tab`}
        data-bs-toggle="tab"
        data-bs-target={`#nav-tabs${index + 1}`}
        type="button"
        role="tab"
        aria-controls={`nav-tabs${index + 1}`}
        aria-selected={activeTab === tab ? 'true' : 'false'}
        onClick={() => setActiveTab(tab)} // Set the active tab on click
      >
        {tab}
      </button>
    ));

  const renderProjects = (tab) => {
    const filteredProjects = getFilteredProjects(tab);

    if (filteredProjects.length === 0) {
      // Display the message when no projects exist for the selected tab
      return (
        <div className="col-12 text-center">
          <p style={{ fontSize: '20px', fontWeight: 'bold', padding: '50px 0' }}>
            {NO_PROJECT_MESSAGE}
          </p>
        </div>
      );
    }

    return filteredProjects.map((project, index) => (
      <div className="col-md-4" key={index}>
        <div className="single-project-inner">
          <img src={`${project.imgFolder}${project.imgName}`} alt={project.title} />
          <div className="details">
            <span>
              <FaPlus />
            </span>
            <Link className="read-more-text" to={project.link}>
              MORE PROJECT
            </Link>
          </div>
        </div>
      </div>
    ));
  };

  return (
    <div className="project-section project-section_1 pd-top-80">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-lg-6">
            <div className="section-title text-center">
              <h2 className="title mt-3">
                Why our clients love us. Latest <span>Project</span> Are Here
              </h2>
            </div>
          </div>
        </div>
        <nav className="text-center">
          <div className="nav nav-tabs project-nav-tab d-inline-flex mb-5" id="nav-tab" role="tablist">
            <button
              className={`nav-link ${activeTab === 'View All' ? 'active' : ''}`}
              id="nav-tabs0-tab"
              data-bs-toggle="tab"
              data-bs-target="#nav-tabs0"
              type="button"
              role="tab"
              aria-controls="nav-tabs0"
              aria-selected={activeTab === 'View All' ? 'true' : 'false'}
              onClick={() => setActiveTab('View All')} // Handle "View All" tab click
            >
              View All
            </button>
            {renderTabs()}
          </div>
        </nav>
        <div className="tab-content" id="nav-tabContent">
          <div className="tab-pane fade show active" id="nav-tabs0" role="tabpanel" aria-labelledby="nav-tabs0-tab">
            <div className="row">{renderProjects('View All')}</div>
          </div>
          {tabs.map((tab, index) => (
            <div
              key={index}
              className={`tab-pane fade ${activeTab === tab ? 'show active' : ''}`}
              id={`nav-tabs${index + 1}`}
              role="tabpanel"
              aria-labelledby={`nav-tabs${index + 1}-tab`}
            >
              <div className="row">{renderProjects(tab)}</div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default ProjectAreaOne;
