import React from "react";
import Layout from "../components/Common/Layout";
import ContactArea from "../components/Contacts/ContactArea";


const Contact = () => {
  return (
    <>
      <Layout breadcrumbTitle="Contact">
        {/* Contact Main */}
        <ContactArea />

      </Layout>
    </>
  );
};

export default Contact;
