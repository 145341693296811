import React from 'react';

const DigitalBanner = () => {
  return (
    <>
      <div className="heading container">
        <h1>Business Grow With Digital Marketing Services?</h1>
        <p className="sub-heading">
          We Employ Your Brand In The Digital World With Our Strategic Digital Marketing Solutions That Enhance <strong>Visibility, Engagement,</strong> and <strong>Conversions</strong> With Our Features!
        </p>
      </div>
      <div className="marketing-section-container">
        <div className="marketing-section">
          <div className="marketing-text">
            <h2>Social Media Marketing</h2>
            <p>
              We are the best Social Media Marketing agency in Goa that builds brand, boosts sales, and drives web traffic via social platforms for overall growth.
            </p>
            <ul>
              <li>Creating and Sharing Engaging Content</li>
              <li>Planning diverse content formats to reach target audiences</li>
              <li>Other associated activities include planning, analysis, engagement, and tracking</li>
              <li>We use platforms like Facebook, Instagram, Twitter, and LinkedIn to foster growth</li>
            </ul>
          </div>
          <div className="marketing-image">
            <img src="./assets/img/Digital.png" alt="Switch to Digital" />
          </div>
        </div>
      </div>
    </>
  );
};

export default DigitalBanner;
