import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { sendEmail } from '../../scripts/EmailService'; // Import the email service
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const ContactAreaOne = () => {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    phone: '',
    subject: '',
    message: ''
  });


  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value, // Dynamically update the form data based on the input name
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      // console.log(process.env.REACT_APP_API_EMAIL_TO);
      await sendEmail({
        fromEmail: formData.email,
        toEmail: process.env.REACT_APP_API_EMAIL_TO, // Email recipient from .env
        subject: formData.subject,
        message: `Name: ${formData.name}\nPhone: ${formData.phone}\nMessage: ${formData.message}`
      });
      toast.success('Message sent successfully!');
    } catch (error) {
      toast.warn('Failed to send email. Please try again.');
    }
  };
  return (
    <>
      {/* ========================= contact Area One start =========================*/}
      <div className="contact-area">
        <div className="container">
          <div className="contact-inner-1">
            <img
              className="top_image_bounce animate-img-1"
              src="assets/img/banner/2.png"
              alt="img"
            />
            <img
              className="top_image_bounce animate-img-2"
              src="assets/img/about/6.png"
              alt="img"
            />
            <div className="row">
              <div
                className="col-lg-8"
                data-aos="fade-right"
                data-aos-delay="200"
                data-aos-duration="1500"
              >
                <img className="w-100" src="assets/img/contact.png" alt="img" />
              </div>
              <div
                className="col-lg-4 wow animated fadeInRight"
                data-aos="fade-left"
                data-aos-delay="200"
                data-aos-duration="1500"
              >
                <div className="section-title mb-0">
                  <h6 className="sub-title">Let's get a quote !</h6>
                  <h2 className="title">
                  </h2>
                  <p className="content">
                    Have an exciting project in mind or a brilliant idea you’d like to discuss? We’re all ears! Fill out the form below,
                    and we’ll get back to you within 24 hours to explore how we can bring your vision to life.
                  </p>
                  <form className="mt-4" onSubmit={handleSubmit}>
                    <div className="row">
                      <div className="col-lg-6">
                        <div className="single-input-inner style-border">
                          <input type="text" placeholder="Your Name" name="name"
                            value={formData.name} // Controlled input
                            onChange={handleChange} />
                        </div>
                      </div>
                      <div className="col-lg-6">
                        <div className="single-input-inner style-border">
                          <input type="text" placeholder="Your Email" name="email"
                            value={formData.email} // Controlled input
                            onChange={handleChange} />
                        </div>
                      </div>
                      <div className="col-lg-6">
                        <div className="single-input-inner style-border">
                          <input type="text" placeholder="Your Phone" name="phone"
                            value={formData.phone} // Controlled input
                            onChange={handleChange} />
                        </div>
                      </div>
                      <div className="col-lg-6">
                        <div className="single-input-inner style-border">
                          <input type="text" placeholder="Your Subject" name="subject"
                            value={formData.subject} // Controlled input
                            onChange={handleChange} />
                        </div>
                      </div>
                      <div className="col-lg-12">
                        <div className="single-input-inner style-border">
                          <textarea placeholder="Message" defaultValue={''} name="message"
                            value={formData.message} // Controlled input
                            onChange={handleChange} />
                        </div>
                      </div>
                      <div className="col-12">
                        <Link
                          className="btn btn-black mt-0 w-100 border-radius-5"
                          to="#"
                        >
                          Submit now
                        </Link>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/*========================= contact-inner One end =========================*/}
    </>
  );
};

export default ContactAreaOne;
