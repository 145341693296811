import React from 'react';
import AboutAreaFour from '../components/About/AboutAreaFour';
import Layout from "../components/Common/Layout";
import ContactAreaOne from '../components/Contacts/ContactAreaOne';
import CounterAreaOne from '../components/Counters/CounterAreaOne.js';
import FaqAreaOne from '../components/Faqs/FaqAreaOne';
import WorkProcess from '../components/Work/WorkProcessEight';
import AboutBottom from '../components/About/AboutBottom.js';
import OurEthics from '../components/OurEthics.js';

const About = () => {
  return (
    <>
      <Layout breadcrumbTitle="About Us">
        {/* About Area Four */}
        <AboutAreaFour />

        {/* ServiceAreaOne */}
        <OurEthics />

        {/* FAQ Area One */}
        <FaqAreaOne />

        {/* Counter Area One */}
        <CounterAreaOne />

        {/* Contact Area One */}
        <ContactAreaOne />

        {/* Work Process */}
        <WorkProcess />

        {/* About Bottom */}
        <AboutBottom />

      </Layout>
    </>
  );
};

export default About;
