import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';

const PrivacyPolicy = () => {
  return (
    <Container className="mt-5 p-5" style={{ backgroundColor: '#fff', borderRadius: '8px', boxShadow: '0 4px 12px rgba(0,0,0,0.1)' }}>
      <Row>
        <Col>
          <h2 className="mb-4 text-primary">Privacy Policy</h2>
          <p><strong>Last Updated:</strong> 25-09-2024</p>

          <h4 className="mt-4">1. Information We Collect</h4>
          <p>We collect personal information that you voluntarily provide to us when you contact us, which may include your name, email address, phone number, and any additional information shared through contact forms or direct communication. When you register for an account, we may gather further details, such as your address, payment information, and preferences related to our services. Additionally, we collect information during subscription to newsletters or marketing communications, as well as billing and shipping details for transactions. Any personal data shared while seeking customer support, including communication logs and support requests, is also collected.</p>
          <br />
          <p>
            In terms of usage data, we gather non-personally identifiable information about how you interact with our website. This includes device information like your IP address, browser type, device type (e.g., desktop or mobile), operating system, and settings. We also collect log data that records actions you take on our site, such as pages viewed, time spent on each page, and the referral URL. Furthermore, we may use cookies, pixel tags, and other tracking technologies to gather data on your interactions, including preferences, saved settings, and user behavior, which can be managed through your browser settings.</p>

          <h4 className="mt-4">2. How We Use Your Information</h4>
          <p>We use the information we collect to provide and maintain our services, ensuring that you have access to the features and functionality of our website. This includes responding to your inquiries, addressing support requests, and resolving any issues you may encounter. Additionally, we use your data to continuously improve our website and services, tailoring our offerings to better meet your needs and enhance your overall experience. Your information helps us understand how our users interact with our platform, allowing us to make informed decisions to optimize performance and user satisfaction.</p>
          <ul>
            <li>Provide and maintain our services.</li>
            <li>Respond to inquiries and support requests.</li>
            <li>Improve our website and services.</li>
          </ul>

          <h4 className="mt-4">3. Data Security</h4>
          <p>
            We implement all reasonable and appropriate measures to safeguard your personal information from unauthorized access, use, or disclosure. This includes employing industry-standard security practices, such as encryption, secure servers, and firewalls, to protect your data during storage and transmission. We regularly review and update our security protocols to mitigate potential vulnerabilities and ensure the ongoing protection of your personal information.
          </p>

          <h4 className="mt-4">4. Cookies</h4>
          <p>
            We may use cookies and similar tracking technologies to enhance your experience on our website by remembering your preferences and providing personalized content. Cookies help us understand how you interact with our site and allow us to improve functionality and performance. You have the option to opt-out or manage your cookie preferences at any time through your browser settings. Please note that disabling cookies may affect certain features or functionalities of the site.
          </p>

          <h4 className="mt-4">5. Third-Party Services</h4>
          <p>
            We may engage third-party service providers, such as analytics platforms, payment gateways, and other service providers, to collect, store, and process data on our behalf. These third parties assist us in delivering services more effectively by providing specialized tools and functionalities. Rest assured, any third-party service providers we work with are obligated to adhere to strict privacy standards and are required to protect your data with the same level of security and confidentiality that we uphold.
          </p>

          <h4 className="mt-4">6. Your Rights</h4>
          <p>
            You have the right to access, correct, or request the deletion of your personal information at any time. If you wish to review the data we hold about you, make updates to your information, or request its removal from our systems, please reach out to us at official@raxos.in. We are committed to responding to your requests promptly and ensuring that your rights to privacy and data protection are fully respected.
          </p>

          <h4 className="mt-4">7. Changes to This Policy</h4>
          <p>
            We may revise our Privacy Policy periodically to reflect changes in our practices, services, or legal requirements. Any updates will be posted on this page, and we will update the "Last Revised" date to indicate when changes were made. We encourage you to review this policy regularly to stay informed about how we protect your personal information.
          </p>
        </Col>
      </Row>
    </Container>
  );
};

export default PrivacyPolicy;
