import React from 'react';
import Layout from '../components/Common/Layout';
import KeyFactors from '../components/KeyFactors';
import WorkArea from '../components/Work/WorkArea';
import Ecommerce from '../components/Ecommerce';
import ContactAreaFour from "../components/Contacts/ContactAreaFour";

const ECommerce = () => {
  return (
    <Layout breadcrumbTitle={'E-Commerce Solutions'}>
      {/* Service Area One */}
      <Ecommerce />

      {/* WorkArea */}
      <WorkArea />

      {/* keyFactor */}
      <KeyFactors />

      {/* Contact */}
      <ContactAreaFour />
    </Layout>
  );
};

export default ECommerce