import React from "react";
import Layout from "../components/Common/Layout";
import TermsConditions from "../components/Common/TermsConditions";

const Contact = () => {
    return (
        <Layout breadcrumbTitle="Terms & Conditions">
            {/* Terms & Conditions  */}
            <TermsConditions />

        </Layout>
    );
};

export default Contact;
