import React from "react";

const MobileBenfit = () => {
  return (
    <section className="who-can-benefit">
      <h2 className="title">Who Can Benefit From Our Services</h2>
      <div className="cards">
        <div className="card">
          <h3>Small & Medium Businesses</h3>
          <p>
            SMEs need customized mobile apps to thrive in today’s digital
            landscape. To reach your target audience and stay competitive on the
            market, you should hire the best mobile app developers.
          </p>
        </div>
        <div className="card">
          <h3>Startups</h3>
          <p>
            Startups looking to make a mark in the digital space can benefit
            from working with startup app development companies. These companies
            have innovative solutions that can help boost growth, increase
            performance, and boost productivity.
          </p>
        </div>
        <div className="card">
          <h3>Large Enterprises</h3>
          <p>
            Streamline your operations and improve your customer experience with
            professional app development. Empower your workforce to deliver
            value by leveraging ERP app development services.
          </p>
        </div>
      </div>
    </section>
  );
};

export default MobileBenfit;
