import React from 'react';

const Card = ({ image, title, link }) => {
  return (
    <div className="card">
      <div className="card-image" >
        <img src={image} alt={title} />
      </div>
      <div className="card-content">
        <h3>{title}</h3>
        <a href={link} target='_blank' className="arrow-link">→</a>
      </div>
    </div>
  );
};

const WorkArea = () => {
  const cards = [
    { image: './assets/img/client/mpvcity/mpvcity.png', title: 'MPVCity', link: 'https://mpvcity.com/' },
    { image: './assets/img/client/rathis/rathis.png', title: "Rathi's Naturals", link: 'https://rathisnaturals.in/' },
  ];

  return (
    <>
      <div className="heading-container">
        <h2>Ideas that turned into a reality</h2>
        <p>Our Work</p>
      </div>
      <div className="card-container container">
        {cards.map((card, index) => (
          <Card key={index} image={card.image} title={card.title} link={card.link} />
        ))}

      </div>
    </>
  );
};

export default WorkArea;
