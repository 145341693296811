import React from 'react';

const Ecommerce = () => {
  return (
    <div className="ecommerce-section-container">
      <div className="ecommerce-section">
        <div className="ecommerce-text">
          <h2>E-Commerce Web Solutions</h2>
          <p>
            Raxos specializes in creating advanced custom e-commerce websites that look good and are super easy to use. Based in Goa, we make online shopping a breeze for your esteemed customers, boosting your sales effortlessly.
          </p>
        </div>
        <div className="ecommerce-image">
          <img src="./assets/img/Ecommerce.jpeg" alt="Switch to Digital" />
        </div>
      </div>
      <div className="ecommerce-section">
        <div className="image-container">
          <div className='ecommerce-card'>
            <div className='ecommerce-card-image'>
              <img src="./assets/img/client/rathis/rathis.png" alt="Switch to Digital" className="e-image" />
            </div>
          </div>
        </div>
        <div className="text-container">
          <h2>Why do you need an E-commerce Web Solution?</h2>
          <p>
            E-commerce offers customers a convenient, round-the-clock shopping experience with a vast selection of products, detailed information, and secure, seamless transactions. It saves time, eliminates geographical limitations, and allows for personalized recommendations based on customer preferences. Additionally, e-commerce platforms often provide multiple payment options, easy return policies, and instant customer support, enhancing the overall shopping journey. This digital approach makes shopping more accessible and efficient for both consumers and retailers.
          </p>
        </div>
      </div>
    </div>
  );
};

export default Ecommerce;
