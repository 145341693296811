import React, { useState } from 'react';
import { FaPlus } from 'react-icons/fa';
import { Link } from 'react-router-dom';
import { sendEmail } from '../../scripts/EmailService'; // Import the email service
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const ContactAreaFour = () => {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    phone: '',
    subject: '',
    message: ''
  });


  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value, // Dynamically update the form data based on the input name
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      // console.log(process.env.REACT_APP_API_EMAIL_TO);
      await sendEmail({
        fromEmail: formData.email,
        toEmail: process.env.REACT_APP_API_EMAIL_TO, // Email recipient from .env
        subject: "Need Help For Client",
        message: `Name: ${formData.name}\nPhone: ${formData.phone}\nMessage: ${formData.message}`
      });
      toast.success('Message sent successfully!');
    } catch (error) {
      toast.warn('Failed to send email. Please try again.');
    }
  };
  return (
    <>
      {/*================= counter area start {/*=================*/}
      <div className="contact-area pd-top-90 pd-bottom-120">
        <div className="container">
          <div className="row">
            <div
              className="col-lg-6 order-lg-end "
              data-aos="fade-left"
              data-aos-delay="200"
              data-aos-duration="1500"
            >
              <div className="about-thumb-inner ms-xl-5 p-xl-5 pt-4 ">
                <img
                  className="animate-main-img"
                  src="assets/img/about/18.png"
                  alt="img"
                />
                <img
                  className="main-img m-4"
                  src="assets/img/Contact-Us.png"
                  alt="img"
                />
              </div>
            </div>
            <div
              className="col-lg-6 order-lg-first "
              data-aos="fade-right"
              data-aos-delay="200"
              data-aos-duration="1500"
            >
              <div className="section-title mb-0 mt-5 mt-lg-0">
                <h6 className="sub-title">GET IN TOUCH</h6>
                <h2 className="title">
                  Your Business To <span>Growth</span> Please Touch ?
                </h2>
                <p className="content">
                  For your Business, we provide comprehensive services, including support, repairs, and maintenance. We are the preferred choice for many businesses due to our extensive experience and expertise. Our knowledge and dedication are evident in everything we do for your IT needs.
                </p>
                <form className="mt-4" onSubmit={handleSubmit}>
                  <div className="row">
                    <div className="col-lg-6">
                      <div className="single-input-inner style-border">
                        <input type="text" placeholder="Name Here" name="name"
                          value={formData.name} // Controlled input
                          onChange={handleChange} />
                      </div>
                    </div>
                    <div className="col-lg-6">
                      <div className="single-input-inner style-border">
                        <input type="text" placeholder="Contact Here" name="phone"
                          value={formData.phone} // Controlled input
                          onChange={handleChange} />
                      </div>
                    </div>
                    <div className="col-lg-12">
                      <div className="single-input-inner style-border">
                        <input type="text" placeholder="Email Here" name="email"
                          value={formData.email} // Controlled input
                          onChange={handleChange} />
                      </div>
                    </div>
                    <div className="col-lg-12">
                      <div className="single-input-inner style-border">
                        <textarea placeholder="Message" name="message"
                          value={formData.message} // Controlled input
                          onChange={handleChange} />
                      </div>
                    </div>
                    <div className="col-12">
                      <button className="btn btn-border-gray mt-0">
                        Contact Us <FaPlus />
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/*{/*================= counter area end {/*=================*/}
    </>
  );
};

export default ContactAreaFour;
