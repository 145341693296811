import React from "react";
import MobileBanner from "../components/Mobile/MobileBanner";
import Layout from "../components/Common/Layout";
import MobileCard from "../components/Mobile/MobileCard";
import MobileBottom from "../components/Mobile/MobileBottom";
import MobileBenfit from "../components/Mobile/MobileBenfit";
import ContactAreaFour from "../components/Contacts/ContactAreaFour";
import FaqAreaTwo from "../components/Faqs/FaqAreaTwo";

const MobileDevelopement = () => {
  return (
    <>
      <Layout breadcrumbTitle="Mobile App Development">
        {/*Mobile Banner */}
        <MobileBanner />

        {/*Mobile Card */}
        <MobileCard />

        {/*Mobile Bottom */}
        <MobileBottom />

        {/*Mobile MobileBenfit */}
        <MobileBenfit />

        {/*Mobile MobileFaqs */}
        <FaqAreaTwo />

        {/* Contact */}
        <ContactAreaFour />
      </Layout>
    </>
  );
}

export default MobileDevelopement;