import { useEffect, useState } from "react";

const TawkToWidget = () => {
    useEffect(() => {
        const script = document.createElement("script");
        script.async = true;
        script.src = "https://embed.tawk.to/66f44bc8e5982d6c7bb440ce/1i8l4g2lb"; // Your property ID
        script.charset = "UTF-8";
        script.setAttribute("crossorigin", "*");

        // Append the Tawk.to script to the body
        document.body.appendChild(script);

        // Cleanup the script when the component unmounts
        return () => {
            document.body.removeChild(script);
        };
    }, []);
};

export default TawkToWidget;
