import React from "react";

const MobileBanner = () => {
  return (
    <>
      {/*=================== service area start ===================*/}
      <div className='about-area pd-top-60'>
        <div className='container'>
          <div className='row'>
            <div
              className='col-lg-6 '
              data-aos='fade-right'
              data-aos-delay='100'
              data-aos-duration='1500'
            >
              <div className='section-title mt-7 mt-lg-1'>
                <h6 className='sub-title'></h6>
                <h2 className='title'>
                  <span>We Design and Develop Awesome Mobile Apps</span>
                </h2>
                <p className='content mb-4 mb-xl-8'>
                  For reliable and timely mobile app development services, choose Neetable. As one of the top mobile app development firms, We provide iOS, Android, and cross-platform development services to power businesses and start-ups. As a team of professionals, we've handled a lot of projects and gained a lot of experience that can be helpful to your business.
                </p>
                <ul>
                  <li>We comprise a team of experienced analysts, UX UI designers, developers, QA, and testing specialists</li>
                  <li>With our expertise, we assist you in meeting your business objectives and requirements.</li>
                  <li> We create high-quality iOS and Android apps that surpass your expectations.</li>
                  <li>Our app development company is a leader when it comes to building scalable and responsive mobile apps.</li>
                  <li> We will not only help you sustain your business but also expand it through our extensive experience.</li>
                </ul>
                <div className='row'>
                  <div className='col-md-6'>
                    <div className='single-about-inner'>
                      <div className='thumb mb-3'>
                        <img src='assets/img/icon/2.png' alt='img' />
                      </div>
                      <div className='details'>
                        <h5>Our Ambition</h5>
                        <p>
                          Our company specializes in creating innovative
                          designs and delivering exceptional solutions tailored
                          to the needs of businesses across the country.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className='col-md-6'>
                    <div className='single-about-inner'>
                      <div className='thumb mb-3'>
                        <img src='assets/img/icon/3.png' alt='img' />
                      </div>
                      <div className='details'>
                        <h5>Our Approach</h5>
                        <p>
                          Our approach helps find the best IT solutions for business transformation
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

            </div>
            <div className='col-lg-6'>
              <div
                className='about-thumb-inner pe-xl-9   me-xl-9 '
                data-aos='fade-left'
                data-aos-delay='100'
                data-aos-duration='1500'
              >
                <img
                  className='animate-img-1 top_image_bounce'
                  src='assets/img/about/2.png'
                  alt='img'
                />
                <img
                  className='animate-img-2 left_image_bounce'
                  src='assets/img/about/3.png'
                  alt='img'
                />
                <img
                  className='animate-img-3 top_image_bounce'
                  src='assets/img/banner/5.svg'
                  alt='img'
                />
                <img
                  className='mobile-img'
                  src='assets/img/mobile-dev.png'
                  alt='img'
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* =================== service area end ===================*/}
    </>
  );
};

export default MobileBanner;
