import React from 'react';
import NavBar from './NavBar';
import Breadcrumb from './Breadcrumb';
import TawkToWidget from './Tawk';
import Footer from './Footer';

const Layout = ({ children, breadcrumbTitle, showBreadcrumb = true }) => {
    return (
        <>
            {/* Navigation Bar */}
            <NavBar />

            {/* Breadcrumb */}
            {showBreadcrumb && <Breadcrumb title={breadcrumbTitle} />}

            {/* Main Content */}
            <main>{children}</main>

            {/* Footers */}
            <Footer />

            <TawkToWidget />
        </>
    );
};

export default Layout;
