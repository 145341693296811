import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';

const TermsConditions = () => {
  return (
    <Container className="mt-5 p-5" style={{ backgroundColor: '#fff', borderRadius: '8px', boxShadow: '0 4px 12px rgba(0,0,0,0.1)' }}>
      <Row>
        <Col>
          <h2 className="mb-4 text-primary">Terms & Conditions</h2>
          <p><strong>Last Updated:</strong> 25-09-2024</p>

          <h4 className="mt-4">1. Introduction</h4>
          <p>
            By accessing and using the website of Raxos Technologies, you agree to be legally bound by these terms and conditions. These terms govern your use of our site and the services we provide. If you do not accept these terms in full, we ask that you refrain from using our site and any associated services. Your access and continued use of this website indicate your acknowledgment and agreement to any modifications or updates to these terms, which may occur from time to time.
          </p>
          <p>
            We reserve the right to change or update these terms at our discretion, and such modifications will become effective immediately upon posting on this page. It is your responsibility to review these terms regularly to ensure you are aware of any changes. Your continued use of the site after such changes are posted constitutes your acceptance of the revised terms.
          </p>
          <p>
            In addition, by using our website, you confirm that you are at least 18 years of age or accessing the site under the supervision of a parent or guardian. Failure to comply with these terms may result in suspension or termination of your access to the site, as well as other legal remedies.
          </p>

          <h4 className="mt-4">2. Services</h4>
          <p>
            Raxos Technologies is committed to delivering a comprehensive suite of services tailored to meet your digital needs. Our offerings include website development, mobile app development, e-commerce solutions, and digital marketing services, all designed to enhance your online presence and drive business success.

            These terms and conditions apply universally to all services available on our website. By using, accessing, or engaging with any of our services, you acknowledge that you have read, understood, and agree to be legally bound by these terms. Your acceptance of these terms is essential for the provision of our services and ensures a clear understanding of our mutual rights and obligations.

            Should you have any questions or concerns regarding these terms, we encourage you to contact us before proceeding with any services. We are dedicated to providing a transparent and effective service experience, and your understanding of these terms is vital to that process.
          </p>

          <h4 className="mt-4">3. Intellectual Property</h4>
          <p>
            All content on this website, including text, graphics, logos, and images, is the property of Raxos Technologies
            and is protected by copyright laws.
          </p>

          <h4 className="mt-4">4. User Responsibilities</h4>
          <p>
            You agree to use the site only for lawful purposes and to refrain from any activity that could harm the website
            or its users.
          </p>

          <h4 className="mt-4">5. Limitation of Liability</h4>
          <p>
            Raxos Technologies will not be liable for any damages that arise from the use or inability to use our website or services.
          </p>

          <h4 className="mt-4">6. Governing Law</h4>
          <p>
            These terms are governed by and construed in accordance with the laws of [Your Country/State].
          </p>
        </Col>
      </Row>
    </Container >
  );
};

export default TermsConditions;
