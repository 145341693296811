import React from "react";
import ProjectAreaGroup from "../components/ProjectAreaGroup";
import Layout from "../components/Common/Layout";
import TeamAreaOne from "../components/TeamAreaOne";
import CounterAreaOne from "../components/Counters/CounterAreaOne";
import ContactAreaOne from "../components/Contacts/ContactAreaOne";
import TechSection from '../components/TechSection';


const Portfolio = () => {
  return (
    <Layout breadcrumbTitle="Portfoilo">

      {/* ProjectAreaGroup */}
      <ProjectAreaGroup />

      {/* TeamAreaOne */}
      <TeamAreaOne />

      {/* Tech Section */}
      {<TechSection />}

      {/* Counter Area One */}
      <CounterAreaOne />

      {/* Contact Area One */}
      <div className="pd-bottom-120">
        <ContactAreaOne />
      </div>
    </Layout>
  );
};

export default Portfolio;