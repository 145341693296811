import React from 'react';

const faqData = [
  {
    question: '1. Why Neetable is the best Mobile app development agency?',
    answer:
      'Our expertise in mobile app development has enabled us to craft a process that ensures high productivity and efficiency. We believe in adapting to new technologies and emerging trends to offer successful mobile applications. Our team will continue to provide support solutions whenever needed.',
  },
  {
    question: '2. How much will it cost to develop mobile app for Android and iOS?',
    answer:
      'This depends on factors like the developer wages, the complexity of the project, estimated project completion duration, and so on. Get in touch with us to know more.',
  },
  {
    question: '3. What is the best mobile app development platform to choose?',
    answer: (
      <>
        <p>This depends on:</p>
        <ul>
          <li>The brand value.</li>
          <li>Who is your target audience?</li>
          <li>What features do you need?</li>
        </ul>
      </>
    ),
  },
  {
    question: '4. What mobile app development services do you provide at Neetable?',
    answer: (
      <>
        <p>Our mobile app development services include:</p>
        <ul>
          <li>Custom iOS and Android app development</li>
          <li>Cross-platform development</li>
          <li>Maintenance and ongoing support</li>
        </ul>
      </>
    ),
  },
];


const FaqAreaTwo = () => {
  return (
    <>
      {/*==================== Faq area start ====================*/}
      <div className="faq-area pd-bottom-120">
        <div className="container">
          <div className="row pd-top-120">
            <div className="col-xl-7 col-lg-6 col-md-8 order-lg-last">
              <div className="about-thumb-inner video-thumb">
                <img
                  className="main-img"
                  src="assets/img/Faq-2.jpeg"
                  alt="img"
                />
              </div>
            </div>
            <div className="col-xl-5 col-lg-6 align-self-center">
              <div className="section-title mb-0 mt-4 mt-lg-0">
                <h6 className="sub-title">YOUR ANSWER</h6>
                <h2 className="title">
                  Have Any <span>Question</span> Please?
                </h2>
              </div>
              <div
                className="accordion accordion-inner style-2 accordion-icon-left mt-3"
                id="accordionExample"
              >
                {faqData.map((faq, index) => (
                  <div className="accordion-item" key={index}>
                    <h2 className="accordion-header" id={`heading${index}`}>
                      <button
                        className={`accordion-button ${index === 0 ? '' : 'collapsed'}`}
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target={`#collapse${index}`}
                        aria-expanded={index === 0 ? 'true' : 'false'}
                        aria-controls={`collapse${index}`}
                      >
                        {faq.question}
                      </button>
                    </h2>
                    <div
                      id={`collapse${index}`}
                      className={`accordion-collapse collapse ${index === 0 ? 'show' : ''}`}
                      aria-labelledby={`heading${index}`}
                      data-bs-parent="#accordionExample"
                    >
                      <div className="accordion-body">
                        {typeof faq.answer === 'string' ? (
                          faq.answer
                        ) : (
                          faq.answer
                        )}
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* ==================== Faq area end ====================*/}
    </>
  );
};

export default FaqAreaTwo;
