import React from 'react';
import { Link } from 'react-router-dom';
const ServiceAreaGroupTwo = () => {
  return (
    <>
      {/* service area start */}
      <div className="service-area bg-relative pd-top-120">
        <div className="container">
          <div className="row">
            <div className="col-lg-4 col-md-6">
              <div className="single-service-inner-2 text-center">
                <div className="thumb">
                  <img src="assets/img/SEO.jpeg" alt="img" />
                </div>
                <div className="details">
                  <div className="icon mb-3">
                    <img src="assets/img/service/9.png" alt="img" />
                  </div>
                  <h5>
                    <Link to="/service-details">SEO</Link>
                  </h5>
                  <p>Unlock the full potential of your business with our comprehensive SEO.</p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6">
              <div className="single-service-inner-2 text-center">
                <div className="thumb">
                  <img src="assets/img/Google-ADs.png " alt="img" />
                </div>
                <div className="details">
                  <div className="icon mb-3">
                    <img src="assets/img/service/8.png" alt="img" />
                  </div>
                  <h5>
                    <Link to="/service-details">Google ADs</Link>
                  </h5>
                  <p>Google Ads increasing leads and conversions.</p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6">
              <div className="single-service-inner-2 text-center">
                <div className="thumb">
                  <img src="assets/img/Facebook-ADs.png" alt="img" />
                </div>
                <div className="details">
                  <div className="icon mb-3">
                    <img src="assets/img/service/10.png" alt="img" />
                  </div>
                  <h5>
                    <Link to="/service-details">Facebook ADs</Link>
                  </h5>
                  <p>Facebook Ads increasing leads and conversions.</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* service area end */}
    </>
  );
};

export default ServiceAreaGroupTwo;
