import React, { useState } from 'react';
import { sendEmail } from '../../scripts/EmailService'; // Import the email service
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const ContactArea = () => {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    phone: '',
    subject: '',
    message: ''
  });


  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value, // Dynamically update the form data based on the input name
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      await sendEmail({
        fromEmail: formData.email,
        toEmail: process.env.REACT_APP_API_EMAIL_TO, // Email recipient from .env
        subject: formData.subject,
        message: `Name: ${formData.name}\nPhone: ${formData.phone}\nMessage: ${formData.message}`
      });
      toast.success('Message sent successfully!');
    } catch (error) {
      toast.warn('Failed to send email. Please try again.');
    }
  };

  return (
    <>
      <div className="solution-area pd-top-120 pd-bottom-120">
        <div className="container">
          <div
            className="p-4 p-xl-5"
            style={{ backgroundImage: 'url("./assets/img/banner/14.png")' }}
          >
            <div className="row">
              <div className="col-lg-6 align-self-center wow animated fadeInRight">
                <div className="section-title style-white mb-0">
                  <h6 className="bg-none color-base mb-3">Our Solution</h6>
                  <h2 className="title">We Are Expert IT Solution</h2>
                  <p className="content mb-4">
                    Business Consulting is a type of service that provides expert advice...
                  </p>
                </div>
              </div>
              <div className="col-lg-6 solution-area-style">
                <div className="section-title mb-0 bg-white p-xl-5 p-4 box-shadow">
                  <h2 className="title">Get Quote Now!</h2>
                  <form className="mt-4" onSubmit={handleSubmit}>
                    <div className="row">
                      <div className="col-lg-6">
                        <div className="single-input-inner style-border">
                          <input
                            type="text"
                            placeholder="Your Name"
                            name="name"
                            value={formData.name} // Controlled input
                            onChange={handleChange} // Handle input changes
                          />
                        </div>
                      </div>
                      <div className="col-lg-6">
                        <div className="single-input-inner style-border">
                          <input
                            type="text"
                            placeholder="Your Email"
                            name="email"
                            value={formData.email} // Controlled input
                            onChange={handleChange} // Handle input changes
                          />
                        </div>
                      </div>
                      <div className="col-lg-6">
                        <div className="single-input-inner style-border">
                          <input
                            type="text"
                            placeholder="Your Phone"
                            name="phone"
                            value={formData.phone} // Controlled input
                            onChange={handleChange} // Handle input changes
                          />
                        </div>
                      </div>
                      <div className="col-lg-6">
                        <div className="single-input-inner style-border">
                          <input
                            type="text"
                            placeholder="Your Subject"
                            name="subject"
                            value={formData.subject} // Controlled input
                            onChange={handleChange} // Handle input changes
                          />
                        </div>
                      </div>
                      <div className="col-lg-12">
                        <div className="single-input-inner style-border">
                          <textarea
                            placeholder="Message"
                            name="message"
                            value={formData.message} // Controlled input
                            onChange={handleChange} // Handle input changes
                          />
                        </div>
                      </div>
                      <div className="col-12">
                        <button
                          type="submit"
                          className="btn btn-black mt-0 w-100 border-radius-5"
                        >
                          Submit now
                        </button>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Toast notification container */}
      <ToastContainer />

      {/* contact list start */}
      <div className="contact-page-list">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-4 col-md-6">
              <div className="media single-contact-list">
                <div className="media-left">
                  <img src="assets/img/icon/13.svg" alt="img" />
                </div>
                <div className="media-body">
                  <h5>Contacts us</h5>
                  <h6>{process.env.REACT_APP_OFFICIAL_PHONE}</h6>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6">
              <div className="media single-contact-list">
                <div className="media-left">
                  <img src="assets/img/icon/14.svg" alt="img" />
                </div>
                <div className="media-body">
                  <h5>Your Email</h5>
                  <h6>{process.env.REACT_APP_OFFICIAL_EMAIL}</h6>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6">
              <div className="media single-contact-list">
                <div className="media-left">
                  <img src="assets/img/icon/15.svg" alt="img" />
                </div>
                <div className="media-body">
                  <h5>Location</h5>
                  <h6>Coimbatore, India</h6>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* contact list start */}
      {/* map start */}
      <div className="contact-g-map">
        <iframe
          src="https://www.google.com/maps/embed?pb=!1m14!1m12!1m3!1d18580.366380323143!2d77.03982522403564!3d11.042384348275242!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!5e1!3m2!1sen!2sin!4v1726692185376!5m2!1sen!2sin"
          title="new title"
        />
      </div>
      {/* map end */}
    </>
  );
};

export default ContactArea;
