import React from "react";

const faqData = [
  {
    question: '1. What website development services do you offer?',
    answer:
      'We provide custom website development, e-commerce solutions, responsive web design, content management systems (CMS) integration, and ongoing maintenance and support.',
  },
  {
    question: '2. How long does it take to develop a website?',
    answer:
      'The timeline for developing a website depends on the complexity of the project and the specific requirements. On average, a standard website takes around 4-8 weeks to complete, including design, development, and testing phases.',
  },
  {
    question: '3. How much does a website cost?',
    answer:
      'The cost of website development varies based on the project’s scope, features, and design requirements. We offer competitive pricing and provide tailored quotes after discussing your project needs.',
  },
  {
    question: '4. Do you offer website maintenance services?',
    answer:
      'Yes, we offer ongoing website maintenance, including updates, performance optimization, security patches, and regular backups to ensure your website runs smoothly over time.',
  },
];

const FaqAreaOne = () => {
  return (
    <>
      {/*==================== Faq area start ====================*/}
      <div
        className='faq-area faq-area-margin-top bg-cover pd-top-90 pd-bottom-110'
        style={{ backgroundImage: 'url("./assets/img/bg/3.png")' }}
      >
        <div className='container'>
          <div className='row pd-top-120'>
            <div
              className='col-xl-5 col-lg-6 col-md-8 order-lg-last'
              data-aos='fade-left'
              data-aos-delay='100'
              data-aos-duration='1500'
            >
              <div className='about-thumb-inner pt-lg-3'>
                <img
                  className='main-img'
                  src='assets/img/banner/Faq.png'
                  alt='img'
                />
                <img
                  className='animate-img-bottom-right top_image_bounce'
                  src='assets/img/banner/5.svg'
                  alt='img'
                />
              </div>
            </div>
            <div
              className='col-xl-7 col-lg-6'
              data-aos='fade-right'
              data-aos-delay='100'
              data-aos-duration='1500'
            >
              <div className='section-title mb-0 mt-4 mt-lg-0'>
                <h6 className='sub-title'>SOME FAQ'S</h6>
                <h2 className='title'>
                  Professional <span>Website</span> Development
                </h2>
                <p className='content'>
                  We specialize in delivering high-quality, responsive websites tailored to your business needs.
                </p>
              </div>
              <div
                className='accordion accordion-inner style-2 accordion-icon-left mt-3'
                id='accordionExample'
              >
                {faqData.map((faq, index) => (
                  <div className='accordion-item' key={index}>
                    <h2 className='accordion-header' id={`heading${index}`}>
                      <button
                        className={`accordion-button ${index !== 0 ? 'collapsed' : ''
                          }`}
                        type='button'
                        data-bs-toggle='collapse'
                        data-bs-target={`#collapse${index}`}
                        aria-expanded={index === 0 ? 'true' : 'false'}
                        aria-controls={`collapse${index}`}
                      >
                        {faq.question}
                      </button>
                    </h2>
                    <div
                      id={`collapse${index}`}
                      className={`accordion-collapse collapse ${index === 0 ? 'show' : ''
                        }`}
                      aria-labelledby={`heading${index}`}
                      data-bs-parent='#accordionExample'
                    >
                      <div className='accordion-body'>{faq.answer}</div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* ==================== Faq area end ====================*/}
    </>
  );
};

export default FaqAreaOne;
